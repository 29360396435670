.form {
  margin: -17rem 1rem 1rem;
  box-shadow: var(--box-shadow-card);
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "choose"
    "amount"
    "firstname"
    "lastname"
    "email"
    "street"
    "postcode"
    "city"
    "country"
    "newsletter"
    "disclaimer"
    "submit";

  @media screen and (min-width: 1000px) {
    & {
      grid-template-areas:
        "choose choose choose"
        "amount amount country"
        "firstname lastname email"
        "street postcode city"
        "newsletter newsletter newsletter"
        "disclaimer disclaimer disclaimer"
        ". submit .";
    }
  }

  &__group {
    width: 100%;

    &--amount {
      grid-area: choose;
    }
  }

  &__label {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: var(--font-weight-headline);

    &__text {
      span {
        display: block;
      }
      &__footer {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-smaller);
      }
    }

    &--with-input {
      display: grid;
      gap: .5rem;

      input {
        padding: 1rem;
        font-size: 1rem;
        border: none;
        box-shadow: 0 0 1px 1px var(--color-primary);
        border-radius: var(--size-radius);

        &:not([type="checkbox"]) {
          -webkit-appearance: none;
        }

        &:focus {
          box-shadow: 0 0 0 2px var(--color-primary-active);
          outline: none;
        }
      }

      input[disabled] {
        color: var(--color-muted);
      }
    }

    &--checkbox {
      display: flex;
      flex-direction: row-reverse;
      font-size: 1rem;
      text-transform: none;
      align-items: flex-start;

      input {
        margin-right: 1rem;
        margin-top: .3em;
        &, &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .payment-disclaimer {
    grid-area: disclaimer;
    padding: 0 1rem;
    font-size: var(--font-size-smaller);
  }
}
