nav {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  background: var(--color-background);
  padding: 2rem 0;

  h1 {
    text-align: center;
    margin: 0;
  }
}
