.terms {
  h1 {
    font-size: 1.2rem;
  }
  main {
    display: grid;
  }

  .layout {
    display: grid;
    justify-content: center;
    padding: 1rem;

    & > .paper {
      margin-top: -26rem;
    }
  }

  &__list {
    box-shadow: var(--box-shadow-card);
    word-break: break-word;
    hyphens: auto;
    ol {
      padding: 0;
      li {
        margin: 1rem 0;
        padding: 0;
      }
    }

    & > ol {
      margin: 2rem 0;
      padding: 0 1rem;
      & > li {
        font-weight: var(--font-weight-headline);
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        li {
          font-weight: var(--font-weight-normal);
        }
      }
    }
  }
}
