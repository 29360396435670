@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;800&display=swap');
@import './variables';
@import './layout';
@import './components/navigation';
@import './components/menu';
@import './components/button';
@import './components/form';
@import './components/success';
@import './components/choose-amount';
@import './components/bank-account';
@import './components/copy-data-app';
@import './components/razem-toast';
@import './components/terms';

.qrcode {
  max-width: min(80%, 400px);
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 800;
  font-size: 1.2em;
  line-height: 1.4em;
}

.logo {
  width: 128px;
  height: 128px;
}
