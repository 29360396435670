copy-data-app {
  display: inline;
  button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    svg {
      fill: var(--color-muted);
    }

    &:hover,
    &:focus {
      border: none;
      outline: none;
      svg {
        fill: var(--color-primary);
      }
    }

    &:active {
      border: none;
      outline: none;

      svg {
        fill: var(--color-primary-active);
      }
    }
  }
}
