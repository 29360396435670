.razem-toast {
  & {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;


  }

  &__content {
    padding: 0 1rem;
    transition: transform 250ms;
    transform: translate3d(0, 5rem, 0);
    margin: 1rem 0;
    width: auto;
    box-shadow: var(--box-shadow-card);
  }

  &--open {
    .razem-toast__content {
      transform: translate3d(0, 0, 0);
    }
  }
}
