@import "https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;800&display=swap";
:root {
  --color-razem-start: #7c1d65;
  --color-razem-intermediate: #d51c4d;
  --color-razem-stop: #ef4348;
  --color-primary: #8a0f59;
  --color-primary-hover: #730d4a;
  --color-primary-active: #5c0a3b;
  --color-main: #000;
  --color-muted: #8d8d8d;
  --color-background: #fff;
  --color-background-muted: #fafafa;
  --color-contrast: #fff;
  --box-shadow: 0 0 1px #000000b3;
  --box-shadow-card: 0 0 100px #0000001a;
  --size-radius: 1rem;
  --size-max-content: 50rem;
  --size-max-content-wide: 55rem;
  --font-weight-normal: 400;
  --font-weight-headline: 800;
  --font-weight-prominent: 500;
  --font-size-smaller: smaller;
}

html, body {
  width: 100%;
  font-family: Mulish, sans-serif;
  font-size: 100%;
  line-height: 1.6em;
  font-weight: var(--font-weight-normal);
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

h1, h2, h3 {
  font-weight: var(--font-weight-headline);
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
  line-height: 2.2rem;
}

h2 {
  font-size: 1.6rem;
  line-height: 1.8rem;
}

p {
  margin: 1rem 0;
}

hr {
  border: none;
  border-bottom: 1px solid #0000001a;
  outline: none;
  height: 0;
  margin: 2rem 0;
}

body {
  background: var(--color-background);
  min-height: 100vh;
  color: var(--color-main);
  flex-direction: column;
  display: flex;
}

body .lead {
  color: var(--color-contrast);
  font-weight: var(--font-weight-prominent);
  justify-content: center;
  gap: 1em;
  margin: 0;
  padding: 2rem 1rem 5rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: grid;
}

body .lead__content {
  max-width: var(--size-max-content);
}

body .lead h1 {
  text-shadow: vaR(--box-shadow);
  text-align: center;
  margin: 2em 0 1em;
}

body .lead p {
  max-width: var(--size-max-content);
  text-shadow: var(--box-shadow);
}

body .lead--razem {
  background: var(--color-primary);
}

body .lead--longer {
  padding-bottom: 26rem;
}

main {
  margin: 0;
  padding: 0;
}

.paper {
  background: var(--color-contrast);
  border-radius: var(--size-radius);
  max-width: var(--size-max-content-wide);
  width: 100%;
  padding: 2rem;
}

.layout--no-background {
  background: none;
  border-radius: 0;
  padding: 0 1rem;
}

.layout--middle {
  justify-content: center;
  padding: 0;
  display: flex;
}

.layout--grid {
  justify-content: center;
  gap: 2rem;
  margin: 0;
  display: grid;
}

footer {
  justify-content: center;
  padding: 8rem 0;
  display: flex;
}

footer .footer__content {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: grid;
}

@media screen and (width >= 500px) {
  footer .footer__content {
    max-width: var(--size-max-content);
    grid-template-columns: 1fr 1fr;
  }
}

footer .footer__content .logo {
  text-align: center;
}

footer .footer__content .logo img {
  width: 8rem;
}

nav {
  width: 100%;
  box-shadow: var(--box-shadow);
  background: var(--color-background);
  place-content: center;
  padding: 2rem 0;
  display: flex;
}

nav h1 {
  text-align: center;
  margin: 0;
}

.menu {
  grid-template-areas: "payu"
                       "prz"
                       "qr";
  gap: 2rem;
  margin: -23rem 0 0;
  padding: 0;
  list-style: none;
  display: grid;
}

.menu li {
  text-align: center;
  background: var(--color-contrast);
  box-shadow: var(--box-shadow-card);
  border-radius: 1rem;
  justify-content: center;
  padding: 2rem;
  display: grid;
}

.menu li h2 {
  margin: 0;
}

.menu #payu {
  grid-area: payu;
}

.menu #payu .button {
  margin-top: 2rem;
}

.menu #przelew {
  grid-area: prz;
}

.menu #qr {
  grid-area: qr;
}

@media screen and (width >= 800px) {
  .menu {
    max-width: var(--size-max-content-wide);
    grid-template-areas: ". payu payu ."
                         "prz prz qr qr";
  }
}

.button-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.button {
  background: var(--color-primary);
  color: var(--color-contrast);
  text-align: center;
  text-transform: uppercase;
  box-shadow: var(--box-shadow);
  text-shadow: var(--box-shadow);
  border: none;
  border-radius: 1rem;
  gap: .5em;
  padding: 1rem 3rem;
  font-size: 1rem;
  font-weight: 800;
  text-decoration: none;
  display: grid;
}

.button:visited {
  color: var(--color-contrast);
}

.button:hover {
  background: var(--color-primary-hover);
}

.button:active {
  background: var(--color-primary-active);
}

.button span:nth-child(2) {
  font-size: 2rem;
}

.form {
  box-shadow: var(--box-shadow-card);
  grid-template-areas: "choose"
                       "amount"
                       "firstname"
                       "lastname"
                       "email"
                       "street"
                       "postcode"
                       "city"
                       "country"
                       "newsletter"
                       "disclaimer"
                       "submit";
  gap: 1rem;
  margin: -17rem 1rem 1rem;
  display: grid;
}

@media screen and (width >= 1000px) {
  .form {
    grid-template-areas: "choose choose choose"
                         "amount amount country"
                         "firstname lastname email"
                         "street postcode city"
                         "newsletter newsletter newsletter"
                         "disclaimer disclaimer disclaimer"
                         ". submit .";
  }
}

.form__group {
  width: 100%;
}

.form__group--amount {
  grid-area: choose;
}

.form__label {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: var(--font-weight-headline);
}

.form__label__text span {
  display: block;
}

.form__label__text__footer {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-smaller);
}

.form__label--with-input {
  gap: .5rem;
  display: grid;
}

.form__label--with-input input {
  box-shadow: 0 0 1px 1px var(--color-primary);
  border-radius: var(--size-radius);
  border: none;
  padding: 1rem;
  font-size: 1rem;
}

.form__label--with-input input:not([type="checkbox"]) {
  -webkit-appearance: none;
}

.form__label--with-input input:focus {
  box-shadow: 0 0 0 2px var(--color-primary-active);
  outline: none;
}

.form__label--with-input input[disabled] {
  color: var(--color-muted);
}

.form__label--checkbox {
  text-transform: none;
  flex-direction: row-reverse;
  align-items: flex-start;
  font-size: 1rem;
  display: flex;
}

.form__label--checkbox input {
  margin-top: .3em;
  margin-right: 1rem;
}

.form__label--checkbox input, .form__label--checkbox input:focus {
  box-shadow: none;
}

.form .payment-disclaimer {
  font-size: var(--font-size-smaller);
  grid-area: disclaimer;
  padding: 0 1rem;
}

body.success_page_body main {
  justify-content: center;
  align-items: center;
  display: flex;
}

body.success_page_body .layout {
  background-size: 100% 50vh;
  max-width: 1240px;
  padding: 1rem;
  display: grid;
}

body.success_page_body video {
  width: 100vw;
  height: 100vw;
  margin: -1rem -1rem 1rem;
}

body.success_page_body .paper {
  z-index: 100;
  margin-top: -4rem;
  margin-bottom: -4rem;
}

@media screen and (width >= 600px) {
  body.success_page_body #thankyou_video {
    text-align: center;
  }

  body.success_page_body main {
    background: var(--color-primary) no-repeat;
    background-size: 100% 600px;
    padding-top: 2rem;
  }

  body.success_page_body video {
    width: 400px;
    height: 400px;
    box-shadow: var(--box-shadow-card);
    border-radius: var(--size-radius);
  }
}

@media screen and (width >= 1200px) {
  body.success_page_body .layout {
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-items: center;
    gap: 1rem;
  }

  body.success_page_body .paper {
    flex-direction: column;
    justify-content: space-around;
    display: flex;
  }
}

.paper.success_page {
  text-align: center;
  font-weight: var(--font-weight-prominent);
  box-shadow: var(--box-shadow-card);
}

.social-media-list {
  justify-content: space-around;
  margin: 1rem 0;
  padding: 0;
  display: flex;
}

.social-media-list li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

.choose-amount {
  border: none;
  width: 100%;
  padding-top: 3rem;
  display: grid;
  position: relative;
}

.choose-amount legend {
  position: absolute;
}

.choose-amount__items {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  font-size: 2rem;
  display: flex;
}

.choose-amount__items input {
  display: none;
}

.choose-amount__items label {
  color: var(--color-razem-intermediate);
  cursor: pointer;
  border-radius: var(--size-radius);
  transition: transform .3s;
  display: flex;
  position: relative;
}

.choose-amount__items input:checked + label {
  color: var(--color-razem-start);
  text-shadow: none;
  transform: scale(1.2);
}

.choose-amount__items__item label div {
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.choose-amount__items__item label div span:last-child {
  padding-left: .5rem;
  font-size: 1rem;
  display: inline-block;
}

@media screen and (width <= 500px) {
  .choose-amount__items__item--custom label div {
    display: grid;
  }
}

.bank-account {
  font-weight: var(--font-weight-headline);
  margin: 1rem 0;
}

.bank-account copy-data-app, .bank-account copy-data-app svg {
  width: 1em;
  height: 1em;
}

copy-data-app {
  display: inline;
}

copy-data-app button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: inline-block;
}

copy-data-app button svg {
  fill: var(--color-muted);
}

copy-data-app button:hover, copy-data-app button:focus {
  border: none;
  outline: none;
}

copy-data-app button:hover svg, copy-data-app button:focus svg {
  fill: var(--color-primary);
}

copy-data-app button:active {
  border: none;
  outline: none;
}

copy-data-app button:active svg {
  fill: var(--color-primary-active);
}

.razem-toast {
  justify-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.razem-toast__content {
  width: auto;
  box-shadow: var(--box-shadow-card);
  margin: 1rem 0;
  padding: 0 1rem;
  transition: transform .25s;
  transform: translate3d(0, 5rem, 0);
}

.razem-toast--open .razem-toast__content {
  transform: translate3d(0, 0, 0);
}

.terms h1 {
  font-size: 1.2rem;
}

.terms main {
  display: grid;
}

.terms .layout {
  justify-content: center;
  padding: 1rem;
  display: grid;
}

.terms .layout > .paper {
  margin-top: -26rem;
}

.terms__list {
  box-shadow: var(--box-shadow-card);
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.terms__list ol {
  padding: 0;
}

.terms__list ol li {
  margin: 1rem 0;
  padding: 0;
}

.terms__list > ol {
  margin: 2rem 0;
  padding: 0 1rem;
}

.terms__list > ol > li {
  font-weight: var(--font-weight-headline);
}

.terms__list > ol > li:first-child {
  margin-top: 0;
}

.terms__list > ol > li:last-child {
  margin-bottom: 0;
}

.terms__list > ol > li li {
  font-weight: var(--font-weight-normal);
}

.qrcode {
  max-width: min(80%, 400px);
}

.text-center {
  text-align: center;
}

.bold {
  font-size: 1.2em;
  font-weight: 800;
  line-height: 1.4em;
}

.logo {
  width: 128px;
  height: 128px;
}
/*# sourceMappingURL=dziekujemy.41b24c98.css.map */
