.menu {
  list-style: none;
  display: grid;
  gap: 2rem;
  padding: 0;
  margin: -23rem 0 0;
  grid-template-areas:
      "payu"
      "prz"
      "qr";

  li {
    display: grid;
    justify-content: center;
    text-align: center;
    background: var(--color-contrast);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: var(--box-shadow-card);

    h2 {
      margin: 0;
    }
  }

  #payu {
    grid-area: payu;

    .button {
      margin-top: 2rem;
    }
  }

  #przelew {
    grid-area: prz;
  }

  #qr {
    grid-area: qr;
  }
}

@media screen and (min-width: 800px) {
  .menu {
    max-width: var(--size-max-content-wide);
    grid-template-areas:
      ". payu payu ."
      "prz prz qr qr";
  }
}
