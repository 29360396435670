/* no autoplay when reduced motion, react? */
/* TODO https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion */
/* Hacks */
body.success_page_body {
  main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layout {
    display: grid;
    background-size: 100% 50vh;
    padding: 1rem;
    max-width: 1240px;
  }

  video {
    width: 100vw;
    height: 100vw;
    margin: -1rem -1rem 1rem;
  }

  .paper {
    margin-top: -4rem;
    margin-bottom: -4rem;
    z-index: 100;
  }


  @media screen and (min-width: 600px) {
    #thankyou_video {
      text-align: center;

    }
    main {
      padding-top: 2rem;
      background: var(--color-primary) no-repeat;
      background-size: 100% 600px;
    }

    video {
      width: 400px;
      height: 400px;
      box-shadow: var(--box-shadow-card);
      border-radius: var(--size-radius);
    }
  }

  @media screen and (min-width: 1200px) {
    .layout {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      align-content: center;
      justify-items: center;
    }

    .paper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}

.paper.success_page {
  text-align: center;
  font-weight: var(--font-weight-prominent);
  box-shadow: var(--box-shadow-card);
}

.social-media-list {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
  }
}
