.bank-account {
  font-weight: var(--font-weight-headline);
  margin: 1rem 0;

  copy-data-app {
    width: 1em;
    height: 1em;

    svg {
      width: 1em;
      height: 1em;
    }
  }
}
