.choose-amount {
  display: grid;
  border: none;
  width: 100%;
  position: relative;
  padding-top: 3rem;

  legend {
    position: absolute;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 2rem;
    gap: 2rem;

    input {
      display: none;
    }

    label {
      display: flex;
      position: relative;
      color: var(--color-razem-intermediate);
      cursor: pointer;
      border-radius: var(--size-radius);
      transition: transform 300ms ease;

      &:hover {
        //color: lighten($intermediate, 20%);
      }
    }

    input:checked + label {
      color: var(--color-razem-start);
      text-shadow: none;
      transform: scale(1.2);
    }

    &__item label {
      div {
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 800;
        text-transform: uppercase;

        span:first-child {
          //letter-spacing: 0.3rem;
        }

        span:last-child {
          font-size: 1rem;
          display: inline-block;
          padding-left: .5rem;
        }
      }
    }

    @media screen and (max-width: 500px) {
      &__item--custom label div {
        display: grid;
      }
    }
  }
}
