.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background: var(--color-primary);
  color: var(--color-contrast);
  padding: 1rem 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 800;
  display: grid;
  gap: .5em;
  box-shadow: var(--box-shadow);
  text-shadow: var(--box-shadow);
  border: none;

  &:visited {
    color: var(--color-contrast);
  }
  &:hover {
    background: var(--color-primary-hover);
  }
  &:active {
    background: var(--color-primary-active);
  }

  span:nth-child(2) {
    font-size: 2rem;
  }
}
